import { IconWrapper, ISvgIconProps } from "@icon-park/svg/lib/runtime";

export default IconWrapper(
  "Partnership",
  (props: ISvgIconProps) =>
    `<svg width=${props.size} height=${props.size} viewBox="0 0 48 48" fill="none">` +
    `<path fill-rule="evenodd" clip-rule="evenodd" d="M14.5858 6.58579C15.3668 5.80474 16.6332 5.80474 17.4142 6.58579L25.4142 14.5858C26.1953 15.3668 26.1953 16.6332 25.4142 17.4142C24.6332 18.1953 23.3668 18.1953 22.5858 17.4142L16 10.8284L6.82843 20L24 37.1716L34.5858 26.5858C35.3668 25.8047 36.6332 25.8047 37.4142 26.5858C38.1953 27.3668 38.1953 28.6332 37.4142 29.4142L25.4142 41.4142C24.6332 42.1953 23.3668 42.1953 22.5858 41.4142L2.58579 21.4142C1.80474 20.6332 1.80474 19.3668 2.58579 18.5858L14.5858 6.58579Z" fill="${props.colors[0]}"/>` +
    `<path fill-rule="evenodd" clip-rule="evenodd" d="M30.5858 6.58579C31.3668 5.80474 32.6332 5.80474 33.4142 6.58579L45.4142 18.5858C46.1953 19.3668 46.1953 20.6332 45.4142 21.4142L37.4142 29.4142C36.6332 30.1953 35.3668 30.1953 34.5858 29.4142L28 22.8284L23.4142 27.4142C22.7653 28.0632 21.758 28.1872 20.971 27.715L15.971 24.715C15.4429 24.3981 15.0902 23.8556 15.015 23.2444C14.9397 22.6331 15.1503 22.0213 15.5858 21.5858L22.5858 14.5858L30.5858 6.58579ZM20.2255 22.6029L21.69 23.4816L26.5858 18.5858C27.3668 17.8047 28.6332 17.8047 29.4142 18.5858L36 25.1716L41.1716 20L32 10.8284L20.2255 22.6029Z" fill="${props.colors[0]}"/>` +
    `<path fill-rule="evenodd" clip-rule="evenodd" d="M23.5858 31.5858C24.3668 30.8047 25.6332 30.8047 26.4142 31.5858L29.4142 34.5858C30.1953 35.3668 30.1953 36.6332 29.4142 37.4142C28.6332 38.1953 27.3668 38.1953 26.5858 37.4142L23.5858 34.4142C22.8047 33.6332 22.8047 32.3668 23.5858 31.5858Z" fill="${props.colors[0]}"/>` +
    `<path fill-rule="evenodd" clip-rule="evenodd" d="M27.5858 27.5858C28.3668 26.8047 29.6332 26.8047 30.4142 27.5858L33.4142 30.5858C34.1953 31.3668 34.1953 32.6332 33.4142 33.4142C32.6332 34.1953 31.3668 34.1953 30.5858 33.4142L27.5858 30.4142C26.8047 29.6332 26.8047 28.3668 27.5858 27.5858Z" fill="${props.colors[0]}"/>` +
    "</svg>",
);
