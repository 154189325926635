import { IconWrapper, ISvgIconProps } from "@icon-park/svg/lib/runtime";

export default IconWrapper(
  "whatapp",
  (props: ISvgIconProps) =>
    `<svg width=${props.size} height=${props.size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">` +
    `<path d="M17.0282 2.90436C15.1494 1.03256 12.65 0.00106812 9.98993 0C7.33582 0 4.83246 1.03058 2.94128 2.90192C1.04675 4.77646 0.00244141 7.26761 0 9.90738V9.91226C0.000305176 11.51 0.420074 13.1218 1.21689 14.593L0.0273132 20L5.49652 18.756C6.88171 19.454 8.42728 19.8221 9.98611 19.8227H9.99008C12.6437 19.8227 15.1471 18.792 17.0386 16.9205C18.9348 15.0444 19.9796 12.5565 19.9808 9.91501C19.9815 7.29218 18.9331 4.8024 17.0282 2.90436ZM9.98993 18.2617H9.98642C8.58673 18.2611 7.19986 17.9097 5.97595 17.2452L5.71732 17.1048L2.08054 17.932L2.87048 14.3419L2.7182 14.0793C1.96106 12.7736 1.56097 11.3322 1.56097 9.91058C1.56387 5.30899 5.3447 1.56097 9.98962 1.56097C12.2336 1.56189 14.342 2.43179 15.9265 4.01016C17.5349 5.61295 18.4204 7.70981 18.4196 9.91455C18.4178 14.5172 14.6362 18.2617 9.98993 18.2617Z" fill="${props.colors[0]}" style=" transform: scale(2.4)" />` +
    `<path d="M7.27158 5.54153H6.83365C6.68121 5.54153 6.43372 5.5986 6.22437 5.82642C6.01486 6.05438 5.4245 6.60538 5.4245 7.72598C5.4245 8.84659 6.24344 9.92935 6.35757 10.0815C6.47186 10.2335 7.93839 12.6065 10.2611 13.5194C12.1915 14.2781 12.5844 14.1272 13.0032 14.0892C13.4222 14.0514 14.3553 13.5384 14.5457 13.0066C14.7362 12.4748 14.7362 12.0189 14.6791 11.9237C14.6219 11.8288 14.4695 11.7719 14.241 11.658C14.0125 11.544 12.8925 10.9837 12.683 10.9076C12.4734 10.8318 12.3212 10.7938 12.1687 11.0219C12.0163 11.2495 11.5675 11.7763 11.4342 11.9283C11.301 12.0804 11.1676 12.0995 10.939 11.9855C10.7104 11.8712 9.98199 11.6264 9.10904 10.8507C8.42957 10.2469 7.95807 9.47693 7.82471 9.24896C7.6915 9.02115 7.81052 8.89786 7.92511 8.78418C8.0278 8.68225 8.1665 8.54279 8.28079 8.40988C8.39493 8.27682 8.42743 8.18192 8.50372 8.02994C8.57986 7.87796 8.54172 7.7449 8.48465 7.63107C8.42743 7.51709 7.98889 6.39084 7.78564 5.9404C7.61444 5.56107 7.43439 5.54825 7.27158 5.54153Z" fill="${props.colors[0]}" style=" transform: scale(2.4)" />` +
    "</svg>"
);
