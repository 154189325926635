import { IconWrapper, ISvgIconProps } from "@icon-park/svg/lib/runtime";

export default IconWrapper(
  "documents",
  (props: ISvgIconProps) =>
    `<svg width=${props.size} height=${props.size} viewBox="0 0 48 48" fill="none">` +
    '<clipPath id="a">' +
    '<path d="m0 0h48v48h-48z" />' +
    "</clipPath>" +
    `<g clipPath="url(#a)" fill="${props.colors[0]}">` +
    '<path clipRule="evenodd" d="m7.58167 19.4666c0-1.0337.83792-1.8716 1.87161-1.8716h13.27812c1.0336 0 1.8716.8379 1.8716 1.8716s-.8379 1.8716-1.8716 1.8716h-13.27812c-1.03369 0-1.87161-.8379-1.87161-1.8716zm0 7.4865c0-1.0337.83792-1.8717 1.87161-1.8717h5.70842c1.0337 0 1.8716.838 1.8716 1.8717 0 1.0336-.8379 1.8716-1.8716 1.8716h-5.70842c-1.03369 0-1.87161-.838-1.87161-1.8716z" fill-rule="evenodd" />' +
    '<path d="m24.603 11.9801c0 1.0337-.8379 1.8717-1.8716 1.8717h-13.27812c-1.03369 0-1.87161-.838-1.87161-1.8717s.83792-1.8716 1.87161-1.8716h13.27812c1.0336 0 1.8716.8379 1.8716 1.8716z" />' +
    '<path d="m32.0016 25.175h5.5322c1.0337 0 1.8716.8379 1.8716 1.8716s-.8379 1.8717-1.8716 1.8717h-5.5322v-1.8717z" />' +
    '<path d="m31.9293 17.6886h5.6045c1.0337 0 1.8716.8379 1.8716 1.8716s-.8379 1.8716-1.8716 1.8716h-5.6045z" />' +
    '<path d="m31.9293 32.6615h5.6045c1.0337 0 1.8716.8379 1.8716 1.8716s-.8379 1.8716-1.8716 1.8716h-5.6045z" />' +
    '<path clipRule="evenodd" d="m5.00159.00158691c-2.76143 0-5.00000309 2.23857309-5.00000309 5.00000309v30.51801c0 2.7615 2.23857309 5 5.00000309 5h9.77731v2.4822c0 2.7614 2.2386 5 5 5h23.2224c2.7614 0 5-2.2386 5-5v-30.6228c0-2.7614-2.2386-4.99997-5-4.99997h-10.9997v-2.37744c0-2.76143-2.2386-5.00000309-5-5.00000309zm27.00001 11.37741309h10.9997c.5522 0 1 .4477 1 1v30.6228c0 .5523-.4478 1-1 1h-23.2224c-.5523 0-1-.4477-1-1v-2.4822h8.2227c2.7614 0 5-2.2385 5-5zm-28.00001-6.37741c0-.55229.44771-1 1-1h22.00001c.5523 0 1 .44771 1 1v30.51801c0 .5523-.4477 1-1 1h-22.00001c-.55229 0-1-.4477-1-1z" fill-rule="evenodd"/>' +
    "</g>" +
    "</svg>"
);
