import { IconWrapper, ISvgIconProps } from "@icon-park/svg/lib/runtime";

export default IconWrapper(
  "feedback",
  (props: ISvgIconProps) =>
    `<svg width=${props.size} height=${props.size} viewBox="0 0 48 48" fill="none">` +
    `<g clipRule="evenodd" fill="${props.colors[0]}" fill-rule="evenodd">` +
    '<path d="m10 16c0-1.1046.8954-2 2-2h24c1.1046 0 2 .8954 2 2s-.8954 2-2 2h-24c-1.1046 0-2-.8954-2-2z" />' +
    '<path d="m10 24c0-1.1046.8954-2 2-2h20c1.1046 0 2 .8954 2 2s-.8954 2-2 2h-20c-1.1046 0-2-.8954-2-2z" />' +
    '<path d="m4 6c-1.10457 0-2 .89543-2 2v30c0 1.1046.89543 2 2 2h5v3c0 .6932.3589 1.3369.94854 1.7013.58966.3644 1.32596.3975 1.94586.0876l9.5777-4.7889h3.5279.5 18.5c1.1046 0 2-.8954 2-2v-19-2-9c0-1.10457-.8954-2-2-2zm38 11v-7h-36v26h5c1.1046 0 2 .8954 2 2v1.7639l7.1056-3.5528c.2777-.1388.5839-.2111.8944-.2111h4 .5 16.5v-17z" />' +
    "</g>" +
    "</svg>"
);
