import { LoadingFour } from "@icon-park/svg";
import clsx from "clsx";
import { FC } from "react";
import Icon from "../Icon";

interface LoaderProps {
  size?: "sm" | "md" | "lg";
  className?: string;
  inverted?: boolean;
}

const Loader: FC<LoaderProps> = ({ size = "sm", className, inverted }) => {
  const sizes = {
    sm: 16,
    md: 20,
    lg: 48,
  } as const;

  return (
    <Icon
      size={sizes[size]}
      icon={LoadingFour}
      className={clsx("animate-spin flex-none", className, {
        "text-primary-800": !inverted,
        "text-neutral-0": inverted,
      })}
    />
  );
};

export default Loader;
