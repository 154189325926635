import { IconWrapper, ISvgIconProps } from "@icon-park/svg/lib/runtime";

export default IconWrapper(
  "instagramsquare",
  (props: ISvgIconProps) =>
    `<svg width=${props.size} height=${props.size} viewBox="0 0 48 48" fill="none">` +
    `<path d="M34.7432 15.8723C34.5223 15.2739 34.17 14.7323 33.7123 14.2877C33.2677 13.83 32.7264 13.4777 32.1277 13.2568C31.6421 13.0682 30.9126 12.8438 29.569 12.7826C28.1155 12.7163 27.6797 12.702 24 12.702C20.3199 12.702 19.8842 12.7159 18.431 12.7822C17.0874 12.8438 16.3575 13.0682 15.8723 13.2568C15.2736 13.4777 14.7319 13.83 14.2877 14.2877C13.83 14.7323 13.4777 15.2736 13.2565 15.8723C13.0679 16.3579 12.8434 17.0878 12.7822 18.4314C12.7159 19.8845 12.7017 20.3203 12.7017 24.0004C12.7017 27.6801 12.7159 28.1158 12.7822 29.5693C12.8434 30.913 13.0679 31.6425 13.2565 32.1281C13.4777 32.7268 13.8296 33.2681 14.2874 33.7126C14.7319 34.1704 15.2732 34.5227 15.8719 34.7435C16.3575 34.9325 17.0874 35.157 18.431 35.2181C19.8842 35.2844 20.3196 35.2983 23.9996 35.2983C27.6801 35.2983 28.1158 35.2844 29.5686 35.2181C30.9122 35.157 31.6421 34.9325 32.1277 34.7435C33.3296 34.2799 34.2795 33.33 34.7432 32.1281C34.9318 31.6425 35.1563 30.913 35.2178 29.5693C35.2841 28.1158 35.298 27.6801 35.298 24.0004C35.298 20.3203 35.2841 19.8845 35.2178 18.4314C35.1566 17.0878 34.9321 16.3579 34.7432 15.8723ZM24 31.0767C20.0914 31.0767 16.923 27.9086 16.923 24C16.923 20.0914 20.0914 16.9233 24 16.9233C27.9082 16.9233 31.0767 20.0914 31.0767 24C31.0767 27.9086 27.9082 31.0767 24 31.0767ZM31.3564 18.2974C30.4431 18.2974 29.7026 17.5569 29.7026 16.6436C29.7026 15.7302 30.4431 14.9897 31.3564 14.9897C32.2698 14.9897 33.0103 15.7302 33.0103 16.6436C33.0099 17.5569 32.2698 18.2974 31.3564 18.2974Z" fill="${props.colors[0]}" />` +
    `<path d="M28.5938 24C28.5938 26.5371 26.5371 28.5938 24 28.5938C21.4629 28.5938 19.4063 26.5371 19.4063 24C19.4063 21.4629 21.4629 19.4063 24 19.4063C26.5371 19.4063 28.5938 21.4629 28.5938 24Z" fill="${props.colors[0]}" />` +
    `<path d="M44.5382 0H3.46179C1.5498 0 0 1.5498 0 3.46179V44.5382C0 46.4502 1.5498 48 3.46179 48H44.5382C46.4502 48 48 46.4502 48 44.5382V3.46179C48 1.5498 46.4502 0 44.5382 0ZM37.6981 29.6818C37.6315 31.1488 37.3982 32.1504 37.0576 33.0271C36.3417 34.8783 34.8783 36.3417 33.0271 37.0576C32.1508 37.3982 31.1488 37.6311 29.6821 37.6981C28.2125 37.7651 27.743 37.7813 24.0004 37.7813C20.2573 37.7813 19.7882 37.7651 18.3182 37.6981C16.8516 37.6311 15.8496 37.3982 14.9733 37.0576C14.0533 36.7115 13.2206 36.1692 12.5321 35.4679C11.8312 34.7798 11.2888 33.9467 10.9427 33.0271C10.6022 32.1508 10.3689 31.1488 10.3022 29.6821C10.2345 28.2122 10.2188 27.7427 10.2188 24C10.2188 20.2573 10.2345 19.7878 10.3019 18.3182C10.3685 16.8512 10.6014 15.8496 10.942 14.9729C11.2881 14.0533 11.8308 13.2202 12.5321 12.5321C13.2202 11.8308 14.0533 11.2885 14.9729 10.9424C15.8496 10.6018 16.8512 10.3689 18.3182 10.3019C19.7878 10.2349 20.2573 10.2188 24 10.2188C27.7427 10.2188 28.2122 10.2349 29.6818 10.3022C31.1488 10.3689 32.1504 10.6018 33.0271 10.942C33.9467 11.2881 34.7798 11.8308 35.4683 12.5321C36.1692 13.2206 36.7119 14.0533 37.0576 14.9729C37.3986 15.8496 37.6315 16.8512 37.6985 18.3182C37.7655 19.7878 37.7813 20.2573 37.7813 24C37.7813 27.7427 37.7655 28.2122 37.6981 29.6818Z" fill="${props.colors[0]}" />` +
    "</svg>"
);
