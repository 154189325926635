import { IconWrapper, ISvgIconProps } from "@icon-park/svg/lib/runtime";

export default IconWrapper(
  "reload",
  (props: ISvgIconProps) =>
    `<svg width="${props.size}" height="${props.size}" viewBox="0 0 48 48" fill="none">` +
    `<path d="M27.7115 4.2425C28.0214 3.18227 29.132 2.57396 30.1923 2.88379C39.8747 5.71332 46.6032 14.9426 45.9586 25.3489C45.9484 25.5141 45.9363 25.6796 45.9223 25.8453C45.4356 31.6231 42.7567 36.6906 38.7769 40.2981L39.6806 40.3743C40.7813 40.467 41.5984 41.4344 41.5056 42.5351C41.4129 43.6358 40.4455 44.4529 39.3448 44.3601L33.366 43.8564C32.2653 43.7637 31.4482 42.7963 31.5409 41.6956L32.0446 35.7168C32.1374 34.6161 33.1048 33.799 34.2055 33.8917C35.3061 33.9845 36.1232 34.9519 36.0305 36.0526L35.9088 37.4975C39.2683 34.5341 41.531 30.3221 41.9364 25.5095C41.9607 25.2215 41.978 24.9345 41.9886 24.6487C42.296 16.3251 36.8494 8.99652 29.0703 6.72321C28.01 6.41337 27.4017 5.30272 27.7115 4.2425Z" fill="${props.colors[0]}"/>` +
    `<path d="M6.03111 22.9384C5.52619 31.4304 11.0232 38.9631 18.9297 41.2737C19.9899 41.5835 20.5982 42.6942 20.2884 43.7544C19.9786 44.8146 18.8679 45.4229 17.8077 45.1131C7.97159 42.2387 1.18394 32.7597 2.07764 22.1516C2.56433 16.3746 5.24213 11.3086 9.22095 7.70187L8.31909 7.62589C7.21842 7.53316 6.40132 6.56572 6.49405 5.46505C6.58677 4.36438 7.55421 3.54728 8.65488 3.64001L14.6337 4.1437C15.7344 4.23643 16.5515 5.20387 16.4587 6.30454L15.9551 12.2834C15.8623 13.384 14.8949 14.2011 13.7942 14.1084C12.6935 14.0157 11.8764 13.0482 11.9692 11.9476L12.091 10.501C8.73145 13.4638 6.46897 17.6747 6.06352 22.4874C6.05083 22.638 6.04003 22.7884 6.03111 22.9384Z" fill="${props.colors[0]}"/>` +
    "</svg>",
);
