import { IconWrapper, ISvgIconProps } from "@icon-park/svg/lib/runtime";

export default IconWrapper(
  "document",
  (props: ISvgIconProps) =>
    `<svg width=${props.size} height=${props.size} viewBox="0 0 48 48" fill="none">` +
    `<g clipRule="evenodd" fill="${props.colors[0]}" fill-rule="evenodd">` +
    '<path d="m6 6c0-2.20914 1.79086-4 4-4h28c2.2092 0 4 1.79088 4 4v36c0 2.2091-1.7909 4-4 4h-28c-2.20912 0-4-1.7908-4-4zm32 0h-28v36h28z" />' +
    '<path d="m15 12c0-1.1046.8954-2 2-2h14c1.1046 0 2 .8954 2 2s-.8954 2-2 2h-14c-1.1046 0-2-.8954-2-2z" />' +
    '<path d="m15 20c0-1.1046.8954-2 2-2h14c1.1046 0 2 .8954 2 2s-.8954 2-2 2h-14c-1.1046 0-2-.8954-2-2z" />' +
    '<path d="m15 27.9814c0-1.1045.8954-2 2-2h14c1.1046 0 2 .8955 2 2 0 1.1046-.8954 2-2 2h-14c-1.1046 0-2-.8954-2-2z" />' +
    '<path d="m15 36c0-1.1046.8954-2 2-2h6c1.1046 0 2 .8954 2 2s-.8954 2-2 2h-6c-1.1046 0-2-.8954-2-2z" />' +
    "</g>" +
    "</svg>"
);
