import {
  AlarmClock,
  ArrowLeft,
  ArrowRight,
  Attention,
  BalanceTwo,
  BookOpen,
  Briefcase,
  Calculator,
  Calendar,
  Certificate,
  Check,
  CheckOne,
  CheckSmall,
  Close,
  CloseSmall,
  Copy,
  Delete,
  Diamond,
  DocDetail,
  DocSuccess,
  Dollar,
  Dot,
  Down,
  Edit,
  ElectricDrill,
  EveryUser,
  Eyes,
  FileSearchTwo,
  Funds,
  GoldMedal,
  HamburgerButton,
  Handbag,
  Help,
  Home,
  HorizontalTidyUp,
  Info,
  Key,
  Left,
  LeftSmall,
  Like,
  ListView,
  LocalTwo,
  Lock,
  Login,
  Logout,
  Mail,
  Me,
  Message,
  Minus,
  MoreOne,
  Percentage,
  PhoneTelephone,
  Plus,
  Refresh,
  Right,
  RightSmall,
  RightSmallUp,
  SaveOne,
  Search,
  SendOne,
  SettingConfig,
  SettingTwo,
  ShareOne,
  Star,
  Time,
  Timer,
  Transaction,
  TrendingDown,
  TrendingUp,
  Unlock,
  Up,
  Upload,
  User,
  Woman,
  Write,
} from "@icon-park/svg";
import { IIconProps, Icon as IconParkIcon } from "@icon-park/svg/lib/runtime";
import clsx from "clsx";
import { FC } from "react";
import Document from "./Document";
import Documents from "./Documents";
import EqualSign from "./EqualSign";
import Euro from "./Euro";
import Eye from "./Eye";
import EyeOff from "./EyeOff";
import FacebookSquare from "./FacebookSquare";
import Feedback from "./Feedback";
import Filter from "./Filter";
import House from "./House";
import InstagramSquare from "./InstagramSquare";
import LinkedinSquare from "./LinkedinSquare";
import Partnership from "./Partnership";
import Reload from "./Reload";
import RocketTwo from "./RocketTwo";
import SquareMeter from "./SquareMeter";
import TiktokSquare from "./TiktokSquare";
import WeighingScale from "./WeighingScale";
import Whatsapp from "./Whatsapp";
import WhatsappSquare from "./WhatsappSquare";

/**
 * All bundled icons. Add new icons here if necessary.
 */
const includedIcons = {
  AlarmClock,
  ArrowRight,
  ArrowLeft,
  Attention,
  BalanceTwo,
  BookOpen,
  Briefcase,
  Calculator,
  Calendar,
  Certificate,
  Check,
  CheckOne,
  CheckSmall,
  Close,
  CloseSmall,
  Copy,
  Delete,
  Diamond,
  DocDetail,
  DocSuccess,
  Document,
  Documents,
  Dollar,
  Dot,
  Down,
  Edit,
  ElectricDrill,
  EqualSign,
  Euro,
  EveryUser,
  Eye,
  EyeOff,
  Eyes,
  FacebookSquare,
  Feedback,
  Filter,
  FileSearchTwo,
  Funds,
  GoldMedal,
  HamburgerButton,
  Handbag,
  Help,
  Unlock,
  Home,
  House,
  HorizontalTidyUp,
  Info,
  InstagramSquare,
  Key,
  Left,
  LeftSmall,
  Like,
  LinkedinSquare,
  ListView,
  LocalTwo,
  Lock,
  Login,
  Logout,
  Mail,
  Me,
  Message,
  Minus,
  MoreOne,
  Partnership,
  Percentage,
  PhoneTelephone,
  Plus,
  Refresh,
  Reload,
  Right,
  RightSmall,
  RightSmallUp,
  RocketTwo,
  SaveOne,
  Search,
  SendOne,
  SettingConfig,
  SettingTwo,
  ShareOne,
  SquareMeter,
  TiktokSquare,
  Time,
  Timer,
  Transaction,
  TrendingDown,
  TrendingUp,
  Up,
  User,
  WeighingScale,
  Whatsapp,
  WhatsappSquare,
  Woman,
  Write,
  Upload,
  Star,
} satisfies Record<string, IconParkIcon>;

export type IconType = keyof typeof includedIcons;

export interface IconProps extends IIconProps {
  type?: IconType;
  icon?: IconParkIcon;
  className?: string;
}

/**
 * This is a wrapper for the icon park icon library.
 * The purpose of this component is to have the ability to dynamically include icons from a specified set of icons, without loading the whole icon library (2000+ icons).
 */
const Icon: FC<IconProps> = ({ icon, type, className, ...props }) => {
  const iconParkIcon = icon ?? (type && includedIcons[type]);

  if (!iconParkIcon) {
    return null;
  }

  return (
    <span
      className={clsx("inline-block", className, {
        "rotate-180": type === "Login" || type === "Logout",
      })}
      dangerouslySetInnerHTML={{
        __html: iconParkIcon(props),
      }}
    ></span>
  );
};

export default Icon;
