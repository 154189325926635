import { IconWrapper, ISvgIconProps } from "@icon-park/svg/lib/runtime";

export default IconWrapper("whatappsquare", (props: ISvgIconProps) => {
  const color =
    props.colors[0] === "currentColor" ? "#7BCF71" : props.colors[0];

  return (
    `<svg width=${props.size} height=${props.size} viewBox="0 0 48 48" fill="none">` +
    `<path d="M24.5134 11.6689C17.9279 11.6689 12.572 17.0226 12.5691 23.6034C12.5684 25.8585 13.1997 28.0547 14.3947 29.955L14.6785 30.4065L13.4722 34.8109L17.9908 33.6259L18.427 33.8844C20.2599 34.972 22.3612 35.5474 24.5035 35.5481H24.5083C31.0887 35.5481 36.4446 30.1941 36.4475 23.6129C36.4486 20.4236 35.2079 17.425 32.9539 15.1692C30.6998 12.9133 27.702 11.67 24.5134 11.6689ZM31.5359 28.7347C31.2367 29.5726 29.803 30.3376 29.1134 30.4409C28.4949 30.5332 27.7126 30.5717 26.8528 30.2988C26.3313 30.1333 25.663 29.9125 24.8064 29.543C21.2062 27.9888 18.8547 24.3647 18.6753 24.1252C18.4958 23.8857 17.2097 22.1796 17.2097 20.4133C17.2097 18.6475 18.137 17.7792 18.4658 17.4203C18.795 17.061 19.184 16.9713 19.4231 16.9713C19.6622 16.9713 19.9017 16.9735 20.1108 16.9838C20.3313 16.9948 20.6272 16.8999 20.9183 17.5997C21.2175 18.3182 21.9353 20.0841 22.025 20.2635C22.1147 20.4434 22.1744 20.6528 22.0551 20.8923C21.9353 21.1318 21.3706 21.8533 21.1578 22.1195C20.9993 22.3173 20.7909 22.4927 21.0004 22.8519C21.2095 23.2108 21.9302 24.386 22.9969 25.3374C24.368 26.5598 25.5245 26.9385 25.8834 27.1183C26.2419 27.2977 26.4514 27.2677 26.6609 27.0286C26.87 26.7891 27.5581 25.9808 27.7972 25.6216C28.0364 25.2623 28.2759 25.3224 28.6047 25.4421C28.934 25.5615 30.6984 26.4298 31.0573 26.6093C31.4161 26.7891 31.6553 26.8788 31.745 27.0286C31.8351 27.1783 31.8351 27.8965 31.5359 28.7347Z" fill="${color}" />` +
    `<path d="M44.5382 0H3.46179C1.5498 0 0 1.5498 0 3.46179V44.5382C0 46.4502 1.5498 48 3.46179 48H44.5382C46.4502 48 48 46.4502 48 44.5382V3.46179C48 1.5498 46.4502 0 44.5382 0ZM24.5087 37.9735C24.509 37.9735 24.5083 37.9735 24.5087 37.9735H24.5028C22.0986 37.9724 19.7362 37.3696 17.6382 36.2256L10.0236 38.2225L12.0615 30.7811C10.8043 28.6036 10.1429 26.1332 10.144 23.6023C10.1473 15.6848 16.5912 9.24353 24.5087 9.24353C28.351 9.245 31.9578 10.7406 34.6696 13.4546C37.3817 16.1689 38.8744 19.7769 38.8729 23.6136C38.8696 31.5315 32.425 37.9735 24.5087 37.9735Z" fill="${color}" />` +
    "</svg>"
  );
});
