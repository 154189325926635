import { IconWrapper, ISvgIconProps } from "@icon-park/svg/lib/runtime";

export default IconWrapper(
  "Euro",
  (props: ISvgIconProps) =>
    `<svg width=${props.size} height=${props.size} viewBox="0 0 24 24" fill="none" stroke="${props.colors[0]}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">` +
    `<path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"/>` +
    `<circle cx="12" cy="12" r="3"/>` +
    "</svg>",
);
